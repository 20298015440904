
import {Component, Vue, Prop, Watch} from 'vue-property-decorator'
import {DocumentPresetResource} from '@/models/documents/DocumentPresetResource'
import {DocumentPresetRestoreRequest} from '@/requests/documents/DocumentPresetRestoreRequest'
import {DocumentPresetVersionResource} from '@/models/documents/DocumentPresetVersionResource'
import {OrganisationDocumentPresetResource} from '@/models/organisationDocuments/OrganisationDocumentPresetResource'

import NoItems from '@/components/partials/NoItems.vue'
import Accordion from '@/components/misc/Accordion.vue'
import DefaultModal from '@/components/modals/Default.vue'
import TextareaInput from '@/components/inputs/Textarea.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'

@Component({
  components: {NoItems, Accordion, DefaultModal, TextareaInput, SmallLoader}
})
export default class PresetVersionModal extends Vue {

  @Prop()
  private readonly preset!: DocumentPresetResource | OrganisationDocumentPresetResource

  @Prop()
  private readonly isOpen!: boolean

  private isFetchingVersions: boolean = false
  private versions: DocumentPresetVersionResource[] = []

  private versionOpenId: null | number = null
  private restoringVersion: any | null = null
  private restoringVersionForm: DocumentPresetRestoreRequest = new DocumentPresetRestoreRequest({ })
  private isRestoringVersion: boolean = false

  @Watch('isOpen')
  private onIsOpenChange(isOpen: boolean, wasOpen: boolean) {
    if (isOpen) this.fetchVersions()
    if (!isOpen && wasOpen) {
      this.versionOpenId = null
    }
  }

  public get hasVersions(): boolean {
    return this.versions.length > 0
  }

  private get showVersionsEmptyState(): boolean {
    return !this.isFetchingVersions && !this.hasVersions
  }

  private get isRestoringVersionFormValid(): boolean {
    return !!this.restoringVersionForm.message || !this.preset.predefined
  }

  private get isRestoringVersionModalOpen() {
    return !!this.restoringVersion
  }

  private async fetchVersions() {
    this.isFetchingVersions = true
    try {
      this.versions = await this.preset.getVersions()
    } finally {
      this.isFetchingVersions = false
    }
  }

  private close() {
    this.$emit('close')
  }

  private isVersionOpen(version: DocumentPresetVersionResource) {
    return version.id === this.versionOpenId
  }

  private toggleVersion(version: DocumentPresetVersionResource) {
    this.versionOpenId = this.versionOpenId === version.id ? null : version.id
  }

  private async restore(version: DocumentPresetVersionResource) {
    this.restoringVersion = version
  }

  private cancelRestore() {
    this.restoringVersion = null
  }

  private get latestVersion(): number {
    return Math.max(...this.versions.map(({ id }) => id))
  }

  private async restorePreset() {
    if (!this.restoringVersion) return
    this.isRestoringVersion = true
    try {
      await this.preset.restoreVersion({ versionId: this.restoringVersion.id, body: this.restoringVersionForm  })
      this.restoringVersion = null
      this.versionOpenId = null
      this.restoringVersionForm = new DocumentPresetRestoreRequest({ })
      this.$emit('restored')
      await this.fetchVersions()
    } catch (e) {
      console.error(e)
    } finally {
      this.isRestoringVersion = false
    }
  }

}
