
import {documentsModule, userModule} from '@/store'
import {isErrorResource} from '@/helpers/isErrorResource'
import {Component, Prop, Vue} from 'vue-property-decorator'
import {AuthUserResource} from '@/models/users/AuthUserResource'
import {DocumentIndexRequest} from '@/requests/documents/DocumentIndexRequest'
import {DocumentPresetCreateRequest} from '@/requests/documents/DocumentPresetCreateRequest'

import TextInput from '@/components/inputs/Text.vue'
import SwitchInput from '@/components/inputs/Switch.vue'
import DefaultModal from '@/components/modals/Default.vue'
import DropdownInput from '@/components/inputs/Dropdown.vue'

@Component({
  components: {DefaultModal, SwitchInput, DropdownInput, TextInput}
})
export default class PresetCreate extends Vue {

  @Prop()
  private readonly countries!: SelectItem[]

  @Prop()
  private readonly departments!: SelectItem[]

  @Prop()
  private readonly isOpen!: boolean

  @Prop()
  private readonly filters!: DocumentIndexRequest

  private isCreatingPreset: boolean = false
  private form: DocumentPresetCreateRequest = new DocumentPresetCreateRequest()
  private errors: ErrorResponse = {}

  private get user(): AuthUserResource | null {
    return userModule.user || null
  }

  private closeModal() {
    this.$emit('close')
  }

  private async postPreset(): Promise<void> {
    this.errors = {}

    this.form.filters = this.filters

    this.isCreatingPreset = true
    try {
      const data = await documentsModule.postPreset(this.form)
      await documentsModule.getPresets()
      this.form = new DocumentPresetCreateRequest()
      this.$emit('created', data)
      this.closeModal()
    } catch (e) {
      if (isErrorResource(e)) {
        this.errors = e.data.errors
      }
    } finally {
      this.isCreatingPreset = false
    }
  }
}
