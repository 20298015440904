var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',{staticClass:"table-heading"},[(_vm.canSelect)?_c('th',{staticClass:"select"},[(_vm.canSelectMultiple)?_c('button',{class:{ 'is-selected': _vm.isSelected, 'disabled': _vm.selectionDisabled },on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('select')}}},[_c(_vm.isSelected ? 'selectedBoxIcon' : 'selectBoxIcon',{tag:"component"})],1):_vm._e()]):_vm._e(),_vm._l((_vm.headings),function(header,index){return [(header.type == 'new')?_c('th',{key:index,staticClass:"new-heading no-click",attrs:{"nowrap":""}}):(header !== undefined)?_c('th',{key:index,class:{
        'active': _vm.orderBy === header.key,
        'no-click': !header.sortable,
        'record': header.key === 'records',
        'errors': header.key === 'errors',
        'status': header.key === 'status'
      },attrs:{"nowrap":header.key === 'status'},on:{"click":function($event){return _vm.setOrder(header.key)}}},[(header.show)?[_vm._v(" "+_vm._s(_vm.getTranlation((_vm.namespace + ".overview.table.headers." + (header.key)), header.key))+" "),(header.sortable)?_c('caretIcon',{class:{
            'down': _vm.orderBy === header.key && _vm.order === 'desc',
            'up': !(_vm.orderBy === header.key && _vm.order === 'desc')
          }}):_vm._e()]:_vm._e()],2):_vm._e()]}),(_vm.canDelete)?_c('th'):_vm._e(),(_vm.$slots.default)?_c('th',[_vm._t("default")],2):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }