import { DocumentPresetResource } from '@/models/documents/DocumentPresetResource'

export class DocumentPresetPatchRequest {
  public name?: string
  public filters?: Dictionary<string | Array<string | null> | undefined>
  public predefined?: boolean
  public department_ids?: number[]
  public country_ids?: string[]
  public message?: string

  constructor({ preset, filters, message }: { preset?: DocumentPresetResource, filters?: Dictionary<string | Array<string | null> | undefined>, message?: string }) {
    if (preset) {
      this.name = preset.name
      this.predefined = !!preset.predefined
      this.department_ids = preset.department_ids ?? []
      this.country_ids = preset.country_ids ?? []
    }
    if (filters) {
      this.filters = filters
    }
    this.message = message
  }
}
