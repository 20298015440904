
import {Component, Vue, Prop} from 'vue-property-decorator'

@Component
export default class PresetHistoryModal extends Vue {

  @Prop()
  private readonly isOpen!: boolean

  private toggle() {
    this.$emit('toggle')
  }

}
