















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class TextAreaInput extends Vue {
  // Props
  @Prop() private name!: string
  @Prop() private label!: string
  @Prop() private errors!: ErrorResponse[]
  @Prop() private placeholder!: string
  @Prop({ default: 3 }) private rows!: number
  // V-model prop
  @Prop() private value!: string

  // Computed properties
  private get hasErrors(): boolean {
    return typeof this.errors === 'object' ? this.errors.length > 0 : false
  }

  private get errorString(): string | undefined {
    return this.hasErrors ? this.errors.join(', ') : undefined
  }

  private get labelString(): string {
    return this.errorString ? this.errorString : this.label
  }
}
