












































import { Component, Vue, Prop } from 'vue-property-decorator'


@Component
export default class TableHeading extends Vue {

  @Prop(Array)
  private headings!: TableElement[]

  @Prop(String)
  private order!: string

  @Prop(String)
  private orderBy!: string

  @Prop(String)
  private namespace!: string

  @Prop(Boolean)
  private readonly canSelect!: boolean

  @Prop({ default: false })
  private readonly canDelete!: boolean

  @Prop({ default: false })
  private readonly isSelected!: boolean

  @Prop({ default: false })
  private readonly selectionDisabled!: boolean

  @Prop({ default: false })
  private readonly canSelectMultiple!: boolean

  private setOrder(val: string): void {
    let order: string = this.order
    let orderBy: string = this.orderBy
    if (orderBy === val) {
      order = order === 'asc' ? 'desc' : 'asc'
    } else {
      order = 'asc'
      orderBy = val
    }
    this.$emit('set-order', orderBy, order)
  }


  private getTranlation(key: string, optionalKey: string): string {
    return this.$te(key) ? this.$t(key) as string : optionalKey
  }
}
