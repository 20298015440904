export const searchTooltip: string =
`<strong>Specify your search:</strong>
<br><br>
<ol>
  <li>
    <strong>Looking for a specific document?</strong><br>
    Use quotes: “Document title” (e.g “Call for evidence on the review of transparency”).
  </li>
  <li>
    <strong>Looking for documents based on one or more keywords?</strong><br>
    Separate keywords by ‘space’, ‘&’, ‘OR’ (e.g. bill evidence ^ bill & evidence ^ bill OR evidence).
  </li>
  <li>
    <strong>Looking for documents containing more than one connected keywords?</strong><br>
    Separate keywords by ‘AND’ (e.g. bill AND evidence).
  </li>
  <li>
    <strong>Exclude documents with specific keywords</strong><br>
    Exclude keywords by using 'AND NOT' (e.g. Insurance AND NOT PSD2)
  </li>
</ol>`
