
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'

import DefaultModal from '@/components/modals/Default.vue'
import TextareaInput from '@/components/inputs/Textarea.vue'

@Component({
  components: {TextareaInput, DefaultModal}
})
export default class PresetConfirm extends Vue {

  @Prop()
  private readonly isOpen!: boolean

  @Prop()
  private readonly predefined!: boolean

  private form: { message: string } = {
    message: ''
  }

  @Watch('isOpen')
  private isOpenChanged(val: boolean, oldVal: boolean) {
    if (!val && oldVal) {
      this.form.message = ''
    }
  }

  private get canSubmit(): boolean {
    return !!this.form.message || !this.predefined
  }

  private cancel() {
    this.$emit('cancel')
  }

  private confirm() {
    if (!this.canSubmit) return
    this.$emit('confirm', this.form)
  }

}
