



















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class SubHeader extends Vue {

  private get hasTitleSlot(): boolean {
    return !!this.$slots.title
  }

  private get hasMiddleSlot(): boolean {
    return !!this.$slots.middle
  }

  private get hasActionsSlot(): boolean {
    return !!this.$slots.actions
  }
}
